import {createRouter, createWebHistory} from '@ionic/vue-router';

const routes = [
	{
		name: 'classes',
		path: '/classes',
		component: function() {
			return import ('../views/Classes.vue');
		},
	},
	{
		name: 'fit3d-scanner',
		path: '/fit3d-scanner',
		component: function() {
			return import ('../views/Fit3dScanner.vue');
		},
	},
	{
		name: 'memberships',
		path: '/memberships',
		component: function() {
			return import ('../views/Memberships.vue');
		},
	},
	{
		name: 'welcome',
		path: '/',
		component: function() {
			return import ('../views/Welcome.vue');
		},
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
