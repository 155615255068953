<template>
	<IonApp>
		<!--		<IonContent v-if='showWelcome' id='welcome' :class='locationStyleName'>-->
		<IonContent v-if='showWelcome' id='welcome'>
			<ion-router-outlet id='main-content'></ion-router-outlet>
		</IonContent>
		<IonSplitPane v-else content-id='main-content'>
			<ion-menu content-id='main-content' type='overlay'>
				<ion-content :class='locationStyleName'>
					<IonImg src='/assets/images/logo.png' class='pt-4' style='height: 80px;'></IonImg>
<!--					<div v-if='user.firstName'>-->
<!--						<IonIcon src='/assets/icon/person.svg'></IonIcon>-->
<!--						<div>{{ user.firstName }}</div>-->
<!--					</div>-->
					<ion-list id='class-list'>
						<ion-list-header>Booking</ion-list-header>
						<ion-menu-toggle auto-hide='false'>
							<ion-item @click='selectedIndex = "classes"' router-direction='root' router-link='/classes' lines='none' detail='false' class='hydrated' :class='{ selected: routeName === "classes" }'>
								<IonIcon src='/assets/icon/calendar-outline.svg'></IonIcon>
								<IonLabel>Classes</IonLabel>
							</ion-item>
						</ion-menu-toggle>
					</ion-list>

					<ion-list id='account-list'>
						<ion-list-header>Account</ion-list-header>
						<ion-menu-toggle auto-hide='false'>
							<ion-item @click='selectedIndex = "memberships"' router-direction='root' router-link='/memberships' lines='none' detail='false' class='hydrated' :class='{ selected: routeName === "memberships" }'>
								<IonIcon src='/assets/icon/ticket.svg'></IonIcon>
								<IonLabel>Memberships</IonLabel>
							</ion-item>
							<ion-item @click='logout' lines='none' detail='false' class='hydrated'>
								<IonIcon src='/assets/icon/logout.svg'></IonIcon>
								<IonLabel>Logout</IonLabel>
							</ion-item>
						</ion-menu-toggle>
					</ion-list>


				</ion-content>
			</ion-menu>
			<ion-router-outlet id='main-content'></ion-router-outlet>
		</IonSplitPane>
	</IonApp>
</template>

<script>
window.location = "https://www.wellnessliving.com/schedule/nxpt"
// import {IonApp, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonRow, IonSplitPane} from '@ionic/vue';
// import {defineComponent, reactive, ref, watch, computed} from 'vue';
// import {useRoute, useRouter} from 'vue-router';
// import {archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp} from 'ionicons/icons';
// import {DataStore} from '@/data/data-store';
// import {User} from '@/data/user';
//
// export default defineComponent({
// 	name: 'App',
// 	components: {
// 		IonApp,
// 		IonButton,
// 		IonCol,
// 		IonContent,
// 		IonGrid,
// 		IonIcon,
// 		IonInput,
// 		IonImg,
// 		IonItem,
// 		IonLabel,
// 		IonList,
// 		IonListHeader,
// 		IonMenu,
// 		IonMenuToggle,
// 		IonNote,
// 		IonRouterOutlet,
// 		IonRow,
// 		IonSplitPane,
// 	},
// 	setup(props, {}) {
// 		let refreshing = false;
// 		let registration;
// 		let updateExists = false;
//
// 		let updateAvailable = function(event) {
// 			registration = event.detail;
// 			updateExists = true;
//
// 			// Make sure we only send a 'skip waiting' message if the SW is waiting
// 			if(!registration || !registration.waiting) return;
// 			// send message to SW to skip the waiting and activate the new SW
// 			registration.waiting.postMessage({type: 'SKIP_WAITING'});
// 		};
//
// 		document.addEventListener('swUpdated', updateAvailable, {once: true});
//
// 		navigator.serviceWorker.addEventListener('controllerchange', () => {
// 			if(refreshing) return;
// 			refreshing = true;
// 			// Here the actual reload of the page occurs
// 			window.location.reload();
// 		});
//
// 		const user = DataStore.object('user');
//
// 		const loggedIn = ref(false);
// 		const selectedIndex = ref('classes');
//
// 		const route = reactive(useRoute());
// 		const router = useRouter();
// 		const showWelcome = ref(route.name === 'welcome');
// 		const routeName = ref(route.name);
//
// 		watch(() => route.name, () => {
// 			routeName.value = route.name;
// 			if(route.name === 'welcome') {
// 				showWelcome.value = true;
// 			}
// 			else {
// 				showWelcome.value = false;
// 			}
// 		});
//
// 		watch(selectedIndex, () => {
// 			router.push(selectedIndex.value);
// 		});
//
// 		let login = function() {
// 			loggedIn.value = true;
// 		};
//
// 		const enterAsGuest = function() {
// 			router.push('classes');
// 		};
//
// 		return {
// 			routeName,
// 			showWelcome,
// 			loggedIn,
// 			selectedIndex,
// 			login,
// 			enterAsGuest,
// 			user,
// 			logout() {
// 				if(confirm('Are you sure you want to logout?',))
// 					window.location = '/logout';
// 			}
// 		};
// 	}
// });
</script>

<style scoped>
#welcome {
    background-color: black;
}


#welcome:before {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

ion-list-header {
    text-transform: uppercase;
    color: var(--ion-color-medium-shade);
    font-family: Arial;
    font-size: 1rem;
}

ion-item {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-right: 1.5rem;
}

ion-icon {
    margin-left: 1rem;
    margin-right: .5rem;
}


#login {
    background: #fff;
    color: #000;
    display: inline-block;
    padding: 1rem;
    border-radius: 10px;
}

ion-menu ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.5);
}
</style>
